import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import api from '../../../services/API';
import { formatarMoeda } from '../../../utils/format';

const { SearchBar } = Search;

export default function SellOut() {
  const [redes, setRedes] = useState([]);
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [filtros, setFiltros] = useState({
    redeId: '',
    dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
    dataFinal: moment().format('YYYY-MM-DD'),
    buscar: ''
  });
  const [ordenacao, setOrdenacao] = useState({
    campo: 'data',
    direcao: 'desc'
  });

  useEffect(() => {
    carregarRedes();
    buscarDados();
  }, []);

  async function carregarRedes() {
    try {
      const response = await api.get('/api/redes');
      if (response.data && response.data.redes) {
        setRedes(response.data.redes);
      } else {
        toast.error('Formato de resposta inválido ao carregar redes');
      }
    } catch (error) {
      console.error('Erro ao carregar redes:', error);
      toast.error('Erro ao carregar redes');
    }
  }

  async function buscarDados(pageNumber = page, pageSize = sizePerPage) {
    console.log('Iniciando busca de dados...');
    console.log('Estado atual:', { filtros, ordenacao, page, sizePerPage });
    setBuscando(true);
    try {
      const params = {
        ...filtros,
        pagina: pageNumber,
        limite: pageSize,
        ordenacao: JSON.stringify({
          campo: ordenacao.campo,
          direcao: ordenacao.direcao
        })
      };
      console.log('Parâmetros enviados:', params);
      const response = await api.get('/api/sellout', { params });
      console.log('Resposta recebida:', response.data);
      if (response.data) {
        console.log('Atualizando estados com os dados recebidos');
        console.log('Rows:', response.data.rows);
        console.log('Count:', response.data.count);
        setData(response.data.rows || []);
        setTotalSize(response.data.count || 0);
        setPage(pageNumber);
        setSizePerPage(pageSize);
        console.log('Estados atualizados com sucesso');
        console.log('Novo estado data:', response.data.rows);
      }
    } catch (error) {
      console.error('Erro detalhado:', error);
      toast.error('Erro ao carregar dados');
      setData([]);
      setTotalSize(0);
    } finally {
      setBuscando(false);
    }
  }

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    console.log('handleTableChange chamado:', { type, page, sizePerPage, sortField, sortOrder });
    if (type === 'sort') {
      const novaOrdenacao = {
        campo: sortField,
        direcao: sortOrder
      };
      console.log('Nova ordenação:', novaOrdenacao);
      setOrdenacao(novaOrdenacao);
      buscarDados(1, sizePerPage);
    } else if (type === 'pagination') {
      console.log('Mudança de página/tamanho:', { page, sizePerPage });
      buscarDados(page, sizePerPage);
    }
  };

  const handleFiltroChange = (campo, valor) => {
    console.log('handleFiltroChange chamado:', { campo, valor });
    setFiltros(prev => {
      const novosFiltros = {
        ...prev,
        [campo]: valor
      };
      console.log('Novos filtros:', novosFiltros);
      return novosFiltros;
    });
  };

  const handleBuscar = () => {
    console.log('handleBuscar chamado');
    console.log('Estado atual antes da busca:', { filtros, page, sizePerPage });
    buscarDados(1, sizePerPage);
  };

  const colunas = [
    {
      dataField: 'Venda_ID',
      text: 'ID da Venda',
      sort: true,
    },
    {
      dataField: 'id',
      text: 'ID',
      hidden: true
    },
    {
      dataField: 'rede',
      text: 'Revenda',
      sort: true
    },
    {
      dataField: 'cpf',
      text: 'CPF Vendedor',
      sort: true,
      formatter: (cell) => cell ? cell.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : ''
    },
    {
      dataField: 'Nome_Produto_Tabela_Produtos',
      text: 'Produto',
      sort: true
    },
    {
      dataField: 'idProduto',
      text: 'Código Produto',
      sort: true
    },
    {
      dataField: 'data',
      text: 'Data',
      sort: true,
      formatter: (cell) => moment(cell).format('DD/MM/YYYY')
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      sort: true,
      align: 'right'
    },
    {
      dataField: 'valorUnitario',
      text: 'Valor Unitário',
      sort: true,
      align: 'right',
      formatter: (cell) => formatarMoeda(cell)
    },
    {
      dataField: 'valor',
      text: 'Valor Total',
      sort: true,
      align: 'right',
      formatter: (cell) => formatarMoeda(cell)
    },
    {
      dataField: 'valorPontos',
      text: 'Pontos Total',
      sort: true,
      align: 'right'
    },
  ];

  const defaultSorted = [{
    dataField: 'data',
    order: 'desc'
  }];

  const options = {
    page,
    sizePerPage,
    totalSize,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    onPageChange: (page, sizePerPage) => {
      buscarDados(page, sizePerPage);
    },
    onSizePerPageChange: (sizePerPage, page) => {
      buscarDados(page, sizePerPage);
    },
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      )
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">SellOut</h4>
      <hr className="hr-loja mt-0 mb-4" />

      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => handleFiltroChange('redeId', e.target.value)}
            value={filtros.redeId}
          >
            <option value="">Todas</option>
            {Array.isArray(redes) && redes.map((rede) => (
              <option key={rede.id} value={rede.id}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={filtros.dataInicial}
            onChange={(e) => handleFiltroChange('dataInicial', e.target.value)}
            max="2999-12-31"
          />
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={filtros.dataFinal}
            onChange={(e) => handleFiltroChange('dataFinal', e.target.value)}
            max="2999-12-31"
          />
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={handleBuscar}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>

      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={colunas}
          search
        >
          {(props) => {
            console.log('ToolkitProvider props:', props);
            console.log('Data sendo passada para a tabela:', data);
            return (
              <div>
                <div className="d-flex flex-row-reverse">
                  <SearchBar
                    {...props.searchProps}
                    placeholder="Buscar"
                    className="input-theme mr-2"
                  />
                </div>
                <BootstrapTable
                  remote
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  pagination={paginationFactory(options)}
                  onTableChange={handleTableChange}
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                />
              </div>
            );
          }}
        </ToolkitProvider>
      </div>
    </Container>
  );
} 